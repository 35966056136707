<template>
  <div id="gerenciarCandidatos">
    <Header />

    <h3 class="titulo">Gestão de Candidatos</h3>

    <div id="Filtros" class="contorno">
      
      <div class="form-group buscaMaisRefresh col-lg-12">

        <div>
          <h4 class="card-title " :style="cssTitulo">Filtros</h4>
        </div>
        
        <div>
          <b-icon-plus v-b-toggle.collapse-1 class="icone" @click="mostrarFiltros" v-if="this.filtrosVisiveis"></b-icon-plus>
          <b-icon-dash v-b-toggle.collapse-1 class="icone" @click="mostrarFiltros" v-if="!this.filtrosVisiveis"></b-icon-dash>
        </div>
        
      </div>
      
      <b-collapse id="collapse-1" visible class="mt-2">

        <!-- Usado para copiar link para aréa de transferencia -->
        <!-- <input type="text" name="link" id="link" ref="copiar" v-model="link" style="display: none;"> -->
        
        <!-- <textarea class="textarea" ref="copiar">{{ meuTitulo }}</textarea> -->

        <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--FILTRO CANDIDATOS--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
        <div class="form-group buscaMaisRefresh col-lg-12">
          <!-- <label ref="copiar">{{ meuTitulo }}</label> -->
          <b-form-input type="text" class="form-control col-lg-3 mr-3" placeholder="Buscar por palavra chave..." v-model="search"></b-form-input>
          <b-button variant="none" class="form-control col-lg-2 button-atualizar" block @click="refresh" :style="cssVars">Atualizar lista</b-button>
        </div>

      </b-collapse>

    </div>

    <div id="Candidatos" class="contorno">

      <b-tabs active-nav-item-class="font-weight-bold text-uppercase text-danger" active-tab-class="font-weight-bold text-success" content-class="mt-3">
        <b-tab :title="'Todos ('+ resultTodos +')'" @click="getCandidatosPorStatus('')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Em Andamento ('+ resultAndamento +')'" @click="getCandidatosPorStatus('Em andamento')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Em Validação ('+ resultValidacao +')'" @click="getCandidatosPorStatus('Em validação')" :active="abaHabilitada">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Em Aprovação ('+ resultAprocacao +')'" @click="getCandidatosPorStatus('Em aprovação')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Concluido ('+ resultConcluido +')'" @click="getCandidatosPorStatus('Concluídos')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Rejeitado ('+ resultRejeitado +')'" @click="getCandidatosPorStatus('Rejeitados')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
        <b-tab :title="'Alertas ('+ resultAlertas +')'" @click="getCandidatosPorStatus('Alertas')">
          <!-- <p>I'm the first tab</p> -->
        </b-tab>
      </b-tabs>

      <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--LISTA DE CANDIDATOS--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <b-table class="altura" 
        striped 
        responsive 
        hover 
        small
        :fields="fields" 
        :items="itemsFilter" 
        :per-page="perPage" 
        :current-page="currentPage"
      >

        <template #cell(urlFotoPerfil)="data">
          <b-avatar class="avatar-foto-perfil" :src="data.item.urlFotoPerfil" size="4rem"></b-avatar>
        </template>

        <template #cell(nome)="data">
          <tr>
            <td>{{data.item.nome}} <br>
              {{data.item.email }} <br>
              <span class="info-celular">{{data.item.celular }}</span> 
            </td>
          </tr>
        </template>

        <template #cell(status)="data">
          <tr>
            <td>
              <b-badge :variant="data.item.statusCor"> {{ data.item.statusDescricao }}</b-badge>
            </td>

          </tr>
        </template>

        <template #cell(progresso)="data">
   
          
          <div class="bar">
            <!-- {{  porcento="30" ? {'background' :color1} :  variant="info" }} -->
            <div v-if="data.item.progresso <= 20 " class="progress2" v-bind:style="{'background':data.item.color , 'width':data.item.progresso+'%'}"></div>
            <div v-else-if="data.item.progresso > 21 &&  data.item.progresso <= 50 " class="progress3" v-bind:style="{'background':data.item.color , 'width':data.item.progresso+'%'}"></div>
            <div v-else class="progress" v-bind:style="{'background':data.item.color , 'width':data.item.progresso+'%'}"></div>
            <span class="porcento">{{data.item.progresso}}%</span>
          </div>

        </template>

        <template v-slot:cell(actions)="data">
            <!-- <b-button-group> -->
              <b-dropdown variant="none" class='dropdown-acoes' @click="selecionar(data.item)" no-caret :style="cssVars" boundary>
                <template #button-content>
                  <span style="color: var(--font-color)">• • •</span>
                </template>

                <b-dropdown-item v-for="permissao in permissoesUsuario" :key="permissao.id"
                @click="direcionarAcaoCandidato(data.item, permissao.id)">{{ permissao.permissao }}</b-dropdown-item>

                <b-dropdown-item v-if="permissoesUsuario.length == 0">Você não tem nenhuma permissão.</b-dropdown-item>

                <!-- <b-dropdown-item @click="abrirAvaliar(data.item)">Avaliar</b-dropdown-item>
                <b-dropdown-item @click="abrirConsulta(data.item)">Consultar</b-dropdown-item>
                <b-dropdown-item @click="modalHistorico(data.item)">Histórico</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="modalInatividade(data.item)">Cancelar por inatividade</b-dropdown-item>
                <b-dropdown-item @click="modalRemoverCandidato(data.item)">Excluir candidato</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showModalConfirm(data.item, 'Confirma o reenvio da prova?', 10)">Reenviar prova</b-dropdown-item>
                <b-dropdown-item @click="showModalConfirm(data.item, 'Confirma a liberação para captação de documentos?', 11)">Liberar para captação de documentos</b-dropdown-item>
                <b-dropdown-item @click="showModalConfirm(data.item, 'Confirma o reenvio de nova documentação para assinatura?', 12)">Reenviar documentação para assinatura</b-dropdown-item>
                <b-dropdown-item @click="modalHistoricoAssinaturas(data.item)">Histórico de assinaturas</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="enviarMensagem(data.item)">Enviar Mensagem por WhatsApp</b-dropdown-item>
                <b-dropdown-item @click="abrirModalCopiarLink(data.item)">Copiar link envio de documentação</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showModalMeioAssinatura(data.item)">Alterar meio de assinatura do contrato de adesão</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showModalEntrevista(data.item, 'avaliação')">Entrevista</b-dropdown-item>
                <b-dropdown-item @click="showModalEntrevista(data.item, 'consulta')">Consultar entrevista</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showModalAprovacaoContrato(data.item.id)">Aprovação de contrato manual</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="showModalConfirm(data.item, 'Confirma o desbloqueio do candidato?', 18)">Desbloquear</b-dropdown-item> -->

              </b-dropdown>
            <!-- </b-button-group> -->
        </template>


      </b-table>

      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--MODAL HISTÓRICO--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL HISTÓRICO -->
      <div>
        <b-modal ref="modal-historico" hide-footer title="Histórico de Atualizações" size="lg">

          <!-- <div class="d-block">
            <b-card no-body class="overflow-hidden" style="max-width: 540px;">
              <b-row no-gutters>
                <b-col md="2">
                  <b-avatar :src="candidato.urlFotoPerfil" size="4rem "></b-avatar>
                </b-col>
                <b-col md="10">
                  <b-card-body>
                    <h5>{{ candidato.nome }}</h5>
                    {{ candidato.email }}

                  </b-card-body>
                </b-col>
              </b-row>
            </b-card>
          </div> -->
          
          <b-row class="form-group">

            <div style="margin-left: 15px">
              <b-avatar :src="candidato.urlFotoPerfil" size="4rem "></b-avatar>
            </div>

            <div style="margin-left: 20px"> 
              <h5>{{ candidato.nome }}</h5>
              {{ candidato.email }}
            </div>
            
          </b-row>

          <b-table striped responsive hover :fields="fields2" :items="historicos" outlined>
            <template v-slot:cell(actions)="data">

              <b-button variant="light" @click="abrirPdf(data.item)" title="PDF" v-if="data.item.linkForm">
                <b-img center thumbnail fluid src="@/assets/pdf40.png" alt="Image 1"></b-img>
              </b-button>
            </template>
          </b-table>

          <!-- <div class="box-btn">
            <b-button class="btn-fechar text-center mt-2" variant="outline-danger" block @click="hideModalHistorico">fechar</b-button>
          </div> -->

        </b-modal>
      </div>

      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--MODAL INATIVIDADE--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL INATIVIDADES -->
      <div>
        <b-modal ref="modal-inat" hide-footer title="Deseja cancelar a inscrição do candidato?" size="sm">

          <div class="d-block text-center">
            <tr class="feeds">
              <li>
                <div class="dados-candidato ">
                  <b-avatar :src="candidato.urlFotoPerfil" size="5rem "></b-avatar><br>
                  <div>{{ candidato.nome }}<br>
                    {{ candidato.email }}<br>
                    {{ candidato.nomeFuncao }}</div>
                </div>
              </li>
            </tr>
          </div>

          <b-button class="mt-3" variant="outline-success" block @click="saveInatividade">Sim</b-button>
          <b-button class="mt-2" variant="outline-danger" block @click="hideModalInat">Não</b-button>
        </b-modal>
      </div>




      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--MODAL COPIAR LINK--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
      <!-- MODAL COPIAR LINK -->
      <div>
        <b-modal ref="modal-copiar-link" hide-footer title="Copiar link" size="lg">

          <div class="col-lg-12">
            <div class="form-row">

              <div class="form-group col-lg-9">
                <b-form-input id="link" v-model="link" readonly="readonly" ref="copiar"></b-form-input>
              </div>

              <div class="form-group col-lg-3">
                <div class="box-btn">
                  <b-button class="btn-copiar-link text-center" variant="info" block @click="copiarLink">Copiar</b-button>
                </div>
              </div>
            
            </div>
          </div>

        </b-modal>
      </div>



    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--MODAL REMOVER REGISTRO --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL REMOVER REGISTRO -->
      <div>
        <b-modal ref="modal-del-registro" hide-footer title="Deseja excluir o registro?" size="sm">


          <b-button class="mt-3" variant="outline-success" block @click="delCandidato">Sim</b-button>
          <b-button class="mt-2" variant="outline-danger" block @click="$refs['modal-del-registro'].hide()">Não</b-button>
        </b-modal>
      </div>



    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--MODAL ASSINATURAS--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
      <!-- MODAL ASSINATURAS -->
      <div>
        <b-modal ref="modal-assinaturas" hide-footer title="Contrato" size="xl">
          
          <assinaturas :candidato="this.candidato"></assinaturas>

        </b-modal>
      </div>


    <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-- MODAL DE CONFIRMAÇÃO --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL DE CONFIRMAÇÃO -->
      <div>
        <b-modal ref="modal-confirm" hide-footer :title="textoConfirme" size="lg">

          <b-button class="mt-3" variant="outline-success" block @click="confirma">Sim</b-button>
          <b-button class="mt-2" variant="outline-danger" block @click="$refs['modal-confirm'].hide()">Não</b-button>

        </b-modal>
      </div>

      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-- MODAL DE ALTERAÇÃO DE MEIO DE ASSINATURA DO CONTRATO DE ADESÃO --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL DE ALTERAÇÃO DE MEIO DE ASSINATURA DO CONTRATO DE ADESÃO -->
      <div>

        <b-modal ref="modal-alteracao-meio-assinatura" hide-footer title="Alterar meio de assinatura do contrato de adesão">
          
          <label>O meio de assinatura que selecionar vai sobrescrever o definido pela empresa: </label>
          <b-form-select :options="meiosAssinaturaContratoAdesao" v-model="meioAssinaturaCandidato"></b-form-select>
          
          <br>
          <br>

          <b-button class="mt-3" variant="outline-success" block @click="alterarMeioAssinaturaCandidato">Confirmar</b-button>
          <b-button class="mt-2" variant="outline-danger" block @click="hideModalMeioAssinatura">Cancelar</b-button>

        </b-modal>

      </div>

      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-- MODAL DE REGISTRO DE ENTREVISTA --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL DE REGISTRO DE ENTREVISTA -->
      <div>

        <b-modal ref="modal-entrevista" size="lg" hide-footer :title="`Registro de Entrevista - ${candidato.nome}`">
          
          <b-form-textarea v-if="interacaoModalEntrevista == 'avaliação'"
          rows="10" placeholder="Escreva aqui sobre como foi a entrevista:" v-model="candidato.novaEntrevista"/>

          <b-form-textarea v-if="interacaoModalEntrevista == 'carregando'"
          rows="10" placeholder="Escreva aqui sobre como foi a entrevista:" v-model="candidato.novaEntrevista" readonly/>
          
          <div v-if="interacaoModalEntrevista == 'consulta'">

            <b-form-textarea 
            v-for="(entrevista, index) in candidato.entrevistas" 
            :key="entrevista.id" v-model="entrevista.descricao" readonly
            :class="{'mb-4': index !== candidato.entrevistas.length - 1}"/>

          </div>

          <b-row class="mt-4" v-if="interacaoModalEntrevista == 'avaliação'">

            <div class="col-lg-4"><b-button variant="info" block @click="saveEntrevista('salvar')">Salvar</b-button></div>
            
            <div class="col-lg-4"><b-button variant="danger" block @click="saveEntrevista('reprovar')">Reprovar</b-button></div>
            
            <div class="col-lg-4"><b-button variant="success" block @click="saveEntrevista('aprovar')">Aprovar</b-button></div>

          </b-row>

          <h5 v-if="interacaoModalEntrevista == 'carregando'" class="text-center mt-4">Aguarde...</h5>
          
        </b-modal>

      </div>

      <!-- XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX-- MODAL DE APROVAÇÃO DE CONTRATO MANUAL --XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->

      <!-- MODAL DE APROVAÇÃO DE CONTRATO MANUAL -->
      <div>

        <b-modal ref="modal-contrato-manual" hide-footer title="Aprovação de contrato manual">

          <div v-if="aprovacaoContrato === 'manual'">

            <b-form-datepicker
            placeholder="Selecione a data de aprovação do contrato: "
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            :max="new Date().toISOString().split('T')[0]"
            :min="new Date(candidato.dataEmissaoContrato).toISOString().split('T')[0]"
            v-model="candidato.dataAprovacaoContrato"/>

            <b-row class="mt-3">

              <div class="col-lg-6">

                <b-btn variant="success" block @click="aprovarContratoManual(idCandidato)">Confirmar</b-btn>

              </div>

              <div class="col-lg-6">

                <b-btn variant="danger" block @click="hideModalAprovacaoContrato">Cancelar</b-btn>

              </div>

            </b-row>

            

          </div>

          <div v-else-if="aprovacaoContrato === 'hibrido'">

            <span>Escolha a opção de aprovação do contrato:</span>

            <b-row class="mt-3">

              <div class="col-lg-6">
                
                <b-button variant="success" block @click="aprovacaoContrato = 'manual'">Manual</b-button>
              
              </div>
              
              <div class="col-lg-6">
                
                <b-button variant="info" block @click="aprovacaoContrato = 'sim'">Clicksign (eletrônica)</b-button>
              
              </div>

            </b-row>

            <b-button variant="danger" block @click="hideModalAprovacaoContrato" class="mt-3">Cancelar</b-button>

          </div>

          <div v-else-if="aprovacaoContrato === 'sim'">

            <span>Tem certeza que deseja assinar o contrato digitalmente?</span>

            <b-row class="mt-3">

              <div class="col-lg-6">
                
                <b-button variant="success" block @click="reenviarDocumentacaoParaAssinaturas('modal-contrato-manual')">Emitir contrato</b-button>
              
              </div>
              
              <div class="col-lg-6">
                
                <b-button variant="danger" block @click="hideModalAprovacaoContrato">Cancelar</b-button>
              
              </div>

            </b-row>

          </div>
          
        </b-modal>

      </div>

      <!--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX--PÁGINAÇÃO--XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX -->
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>

      <b-row>

        <div v-if="this.exibindoConfiguracaoPaginacao == false">
          <b-icon-gear style="font-size: 20px; margin-left: 20px" @click="exibirConfigPaginacao"></b-icon-gear>
        </div>

        <div v-if="this.exibindoConfiguracaoPaginacao == false">
          <span style="margin-left: 10px">Alterar número de registros por página</span>
        </div>

        <div v-if="this.exibindoConfiguracaoPaginacao == true">
          <b-icon-gear-fill style="font-size: 20px; margin-left: 20px" @click="exibirConfigPaginacao"></b-icon-gear-fill>
        </div>

        <div v-if="this.exibindoConfiguracaoPaginacao == true" class="col-lg-1">
          <b-form-select type="number" :options="optionsPaginacao" v-model="perPage"></b-form-select>
        </div>

      </b-row>

    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import dayjs from "dayjs";
import validaCredencial from "../utils";
import Header from "@/components/Header";
import assinaturas from "./modal/assinaturas";

export default {
  components: {
    Header,
    assinaturas
  },

  name: "ClienteList",
  el: ".box",
  data() {
    return {
      meuTitulo: "Histórico de Atulizações",
      link: '',

      form: {
        nome: "bolinha Lerolero",
        email: "lero@gmail.com",
      },

      idCandidato: null,

      dadosEmpresa: null,
      bgColor: "#a1a1a1",
      fontColor: "#FFFFFF",
      logo: "",
      pdf: "@/assets/pdf.png",

      abaHabilitada: true,

      search: "",
      resultTodos: "",
      load: false,
      itemsTotal: [],
      items: [],
      perPage: 5,
      currentPage: 1,

      candidato: {
        nome: "",
        email: "",
        funcao: "",
        dataEmissaoContrato: "",
        dataAprovacaoContrato: "",
        entrevistas: [],
        novaEntrevista: ""
      },

      fields2: [
        { key: "status", label: "Status", sortable: true },
        { key: "dataAtualizacao", label: "Atualizado em" },
        { key: "actions", label: "Documentação" },
      ],
      historicos: [],

      fields: [
        {
          key: "urlFotoPerfil",
          label: "Img",
        },
        { key: "nome", label: "Nome", sortable: true },
        { key: "cpf", label: "CPF", sortable: true },
        { key: "progresso", label: "Progresso", sortable: true },
        { key: "nomeFuncao", label: "Função", sortable: true },
        { key: "cidade", label: "UF - Cidade", sortable: true },
        { key: "status", label: "Status", sortable: true },
        // { key: "dataCreate", label: "Dt Inicio", sortable: true },
        // { key: "dataAtualizacao", label: "Dt Atualização", sortable: true },
        {key: 'dataCreate', label: 'Data Início', sortable: true , formatter: value => {
            return dayjs(value).format('DD/MM/YYYY')
        }},
        {key: 'dataAtualizacao', label: 'Dt Atualização', sortable: true , formatter: value => {
            return dayjs(value).format('DD/MM/YYYY')
        }},
        { key: "actions", label: "Ações" },
      ],

      filtrosVisiveis: false,
      mensagemWhatsapp: '',

      exibindoConfiguracaoPaginacao: false,
      optionsPaginacao: [5, 10, 15, 20],

      textoConfirme: '',

      meiosAssinaturaContratoAdesao: [],
      meioAssinaturaCandidato: null,

      interacaoModalEntrevista: '',

      aprovacaoContrato: null,

      permissoesUsuario: []

    };
  },

  async created() {

    this.dadosEmpresa = this.$store.state.empresaSelecionada;

    if (this.dadosEmpresa) {
      this.bgColor = this.dadosEmpresa.cor
      this.logo = this.dadosEmpresa.logoPath
      this.color = this.dadosEmpresa.titulo
      this.fontColor = this.dadosEmpresa.corFonte

      this.aprovacaoContrato = this.dadosEmpresa.assinarClicksign

      this.getPermissoesUsuario(this.$store.state.auth.user.idUser, this.dadosEmpresa.id)
      
      this.refresh()

    } else {
      window.location = "/login"
    }

  },

  methods: {

    todos(data) {
      console.log("OK", data);
    },

    msg(titulo, texto, cor, time = 10000) {
      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: time,
        appendToast: true,
      });
    },

    async getCandidatos() {      

      this.items = [];
      this.load = true;
      this.abaHabilitada = true;

      try {

        const res = await api.get("web-cliente/candidatos/" + this.dadosEmpresa.id);

        this.itemsTodos = res.data;
        this.items = res.data;
        this.load = false;
        this.resultTodos = this.items.length;
        this.resultAndamento = this.items.filter((item) => item.status == "Em andamento").length;
        this.resultValidacao = this.items.filter((item) => item.status == "Em validação").length;
        this.resultAprocacao = this.items.filter((item) => item.status == "Em aprovação").length;
        this.resultConcluido = this.items.filter((item) => item.status == "Concluídos").length;
        this.resultRejeitado = this.items.filter((item) => item.status == "Rejeitados").length;
        this.resultAlertas = this.items.filter((item) => item.status == "Alertas").length;

        this.getCandidatosPorStatus("Em validação");

      } catch (error) {
        this.load = false;
        this.msg("ERRO AO BUSCAR CLIENTES", error.response.data, "danger");
      }
    },

    getCandidatosPorStatus(status) {
      this.abaHabilitada = false;

      this.items = status
        ? this.itemsTodos.filter((item) => item.status == status)
        : this.itemsTodos;
    },

    async refresh() {
      await this.getCandidatos();
      this.getCandidatosPorStatus("Em validação");

      this.abaHabilitada = true;
    },

    async abrirConsulta(candidato) {
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(2)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning",5000);
        return
      }
      //------------------------------------------------------------------

      localStorage.setItem("idSolicitacao", candidato.id);
      this.$router.push("/candidato/consultar");
    },

    async abrirAvaliar(candidato) {
      // console.log('candidato.id', candidato.id)
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(1)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning",5000);
        return
      }
      //------------------------------------------------------------------


      if (candidato.idStatus != 6 && candidato.idStatus != 7) {
        this.msg(
          "CANDIDATO NÃO PODE SER AVALIADO",
          "Aguarde...",
          "warning",
          3000
        );
        return;
      }

      // Atualiza status 6 para 7.
      if (candidato.idStatus == 6) {
        api.put("web-cliente/candidato/atualiza-status", {
          idSolicitacao: candidato.id
        });
      }

      localStorage.setItem("idSolicitacao", candidato.id);
      this.$router.push("/candidato/avaliar");

    },

    async modalHistorico(candidato) {
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(3)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 4000);
        return
      }
      //------------------------------------------------------------------
      

      await this.getHistoricos(candidato);


      this.candidato.id = candidato.id;
      this.candidato.nome = candidato.nome;
      this.candidato.email = candidato.email;
      this.candidato.urlFotoPerfil = candidato.urlFotoPerfil;
      this.$refs["modal-historico"].show();
    },

    hideModalHistorico() {
      this.$refs["modal-historico"].hide();
    },

    async getHistoricos(candidato) {
      try {
        const res = await api.get(
          `web-cliente/candidato/historico/${candidato.id}`
        );

        this.historicos = res.data;
      } catch (error) {
        this.msg("ERRO AO BUSCAR HISTÓRICOS", error.response.data, "danger");
      }
    },

    abrirPdf(historico) {
      window.open(historico.linkForm, "_blank");
    },

    async abrirModalCopiarLink(candidato) {

      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(30)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
        return
      }
      //------------------------------------------------------------------



      if (!candidato.linkEnvioDocs) {
        this.msg("CANDIDATO NÃO POSSUI O LINK DE ENVIO", "...", "warning", 3000);
        return; 
      }

      this.link = candidato.linkEnvioDocs.split(' ').join('%20')
      this.$refs["modal-copiar-link"].show();

    },

    copiarLink() {

      // var copyTextarea = document.getElementById("link")
      var copyTextarea = this.$refs.copiar
        
      copyTextarea.select();
      document.execCommand('copy');

    },

    async modalInatividade(candidato) {
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(4)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning",5000);
        return
      }
      //------------------------------------------------------------------


      if (candidato.idStatus == 10) {
        this.msg("CANDIDATO NÃO PODE SER CANCELADO", "...", "warning", 3000);
        return;
      }

      this.candidato.id = candidato.id;
      this.candidato.nome = candidato.nome;
      this.candidato.email = candidato.email;
      this.candidato.nomeFuncao = candidato.nomeFuncao;
      this.candidato.urlFotoPerfil = candidato.urlFotoPerfil;
      this.$refs["modal-inat"].show();
    },

    hideModalInat() {
      this.$refs["modal-inat"].hide();
    },


    async modalRemoverCandidato(candidato) {
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(5)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
        return
      }
      //------------------------------------------------------------------


      this.idCandidato = candidato.id
      this.$refs["modal-del-registro"].show();
    },



    async saveInatividade() {

      try {

        await api.put("web-cliente/candidatos/cancelar", {
          idSolicitacao: this.candidato.id,
        });

        this.$refs["modal-inat"].hide();
        this.msg("Candidato", "cancelado com sucesso.", "success");
        this.refresh();

      } catch (error) {
        this.load = false;
        this.msg("ERRO AO CANCELAR CLIENTES", error.response.data, "danger");
      }
    },

    async delCandidato() {

      try {

        const user = this.$store.state.auth.user
        
        this.$refs["modal-del-registro"].hide();
        await api.delete(`web-cliente/candidato/${this.idCandidato}/${user.nome}`);

        this.msg("Candidato", "Removido com sucesso.", "success");
        this.refresh();

      } catch (error) {
        this.load = false;
        this.msg("ERRO AO REMOVER CANDIDATO", error.response.data, "danger");
      }
    },

    mostrarFiltros() {
      if (this.filtrosVisiveis) this.filtrosVisiveis = false
      else this.filtrosVisiveis = true
    },

    async enviarMensagem(candidato) {

      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(20)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
        return
      }
      //------------------------------------------------------------------



      let numeroSemMascara = candidato.celular.split('-').join('')
      numeroSemMascara = numeroSemMascara.split('(').join('')
      numeroSemMascara = numeroSemMascara.split(')').join('')
      numeroSemMascara = numeroSemMascara.split(' ').join('')

      try {

        const res = await api.get(`web-cliente/candidato/mensagem/` + candidato.idEmpresa);

        const nomeEmpresa = res.data

        this.mensagemWhatsapp = `Olá sr(a) ${candidato.nome}, somos do departamento de adesão da ${nomeEmpresa}`
        this.mensagemWhatsapp = this.mensagemWhatsapp.split(' ').join('%20')

        window.open(`https://wa.me/55${numeroSemMascara}?text=${this.mensagemWhatsapp}`)

      } catch (error) {
        this.msg("ERRO AO BUSCAR MENSAGEM", error.response.data, "danger");
      }

    },

    exibirConfigPaginacao() {
      if (this.exibindoConfiguracaoPaginacao == true) this.exibindoConfiguracaoPaginacao = false
      else this.exibindoConfiguracaoPaginacao = true
    },



    async showModalConfirm(candidato, texto) {
      
      // const acessoPermitido = await validaCredencial(codCredencial)

      // if (acessoPermitido == 'fail') {

      //   this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 3000);
      //   return

      // }

      this.idCandidato = candidato.id
      this.textoConfirme = texto
      this.$refs["modal-confirm"].show();
      
    },

    confirma() {

      if (this.textoConfirme == 'Confirma o reenvio da prova?') 
        this.reenviarProva()
      else
      if (this.textoConfirme == 'Confirma a liberação para captação de documentos?') 
        this.liberarParaCaptacaoDocumentos()
      else
      if (this.textoConfirme == 'Confirma o reenvio de nova documentação para assinatura?') 
        this.reenviarDocumentacaoParaAssinaturas('modal-confirm')
      else
      if (this.textoConfirme == 'Confirma o desbloqueio do candidato?')
        this.desbloquearCandidato()  
      else
      if (this.textoConfirme == 'Confirma a geração do contrato manual?')
        this.gerarContratoManual()  
      
    },

    async reenviarProva() {
      // console.log("candidato", candidato);

      this.$refs["modal-confirm"].hide();

      try {

        await api.post("web-cliente/candidato/reenvia-prova", {
          idSolicitacao: this.idCandidato
        });

        this.msg("O CANDIDATO", "RECEBEU UM NOVO LINK PARA PROVA.", "success");
        this.refresh();

      } catch (error) {

        if (error.response.status == 402)
          this.msg("ATENÇÃO", error.response.data, "warning");
        else
          this.msg("ATENÇÃO", "Erro ao reenviar a prova", "danger");

      }
    },

    async liberarParaCaptacaoDocumentos() {

      this.$refs["modal-confirm"].hide();
      this.msg("AGUARDE", "Liberando a captação...", "info", 2000);

      try {

        await api.post("web-cliente/candidato/liberar-captacao-documentos", {
          idSolicitacao: this.idCandidato
        });

        this.msg("O CANDIDATO", "FOI LIBERADO PARA CAPTAÇÃO DE DOCUMENTOS", "success");
        this.refresh();

      } catch (error) {

        if (error.response.status == 402)
          this.msg("ATENÇÃO", error.response.data, "warning");
        else
          this.msg("ATENÇÃO", "Erro ao liberar captação.", "danger");

      }
    },

    async reenviarDocumentacaoParaAssinaturas(modalRef) {

      this.$refs[modalRef].hide();

      try {

        await api.post("web-cliente/candidato/reenviar-documentacao-assinaturas", {
          idSolicitacao: this.idCandidato
        });

        this.msg("O CANDIDATO", "REENVIADO NOVOS DOCUMENTOS PARA ASSINATURAS.", "success");
        this.refresh();

      } catch (error) {

        if (error.response.status == 402)
          this.msg("ATENÇÃO", error.response.data, "warning");
        else
          this.msg("ATENÇÃO", "Erro ao reenviar novos documentos.", "danger");

      }
    },

    async modalHistoricoAssinaturas(candidato) {
      
      // Valida credenciais
      //------------------------------------------------------------------
      const acessoPermitido = await validaCredencial(3)
      if (acessoPermitido == 'fail') {
        this.msg("ATENÇÃO", "Você não possui permissão para continuar.", "warning", 4000);
        return
      }
      //------------------------------------------------------------------
      

      this.candidato.id = candidato.id;
      this.candidato.nome = candidato.nome;
      this.candidato.email = candidato.email;
      this.candidato.urlFotoPerfil = candidato.urlFotoPerfil;
      this.$refs["modal-assinaturas"].show();
    },

    getMeiosAssinaturaContratoAdesao() {

      api.get('web-cliente/candidato/meios-assinatura')
      .then(res => {

        this.meiosAssinaturaContratoAdesao = res.data

      })
      .catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    showModalMeioAssinatura(candidato) {

      this.idCandidato = candidato.id
      this.$refs['modal-alteracao-meio-assinatura'].show()
      this.getMeiosAssinaturaContratoAdesao()
      this.meioAssinaturaCandidato = candidato.meioAssinatura

    },

    hideModalMeioAssinatura() {

      this.idCandidato = null
      this.$refs['modal-alteracao-meio-assinatura'].hide()

    },

    async alterarMeioAssinaturaCandidato() {

      await api.put('web-cliente/candidato/meio-assinatura', {

        idSolicitacao: this.idCandidato,
        meioAssinatura: this.meioAssinaturaCandidato

      })
      
      this.msg("O MEIO DE ASSINATURA DO CONTRATO DE ADESÃO", "FOI ATUALIZADO COM SUCESSO.", "success", 2000);
      this.hideModalMeioAssinatura()
      this.getCandidatos()

    },

    async showModalEntrevista(candidato, interacao) {

      await api.get(`web-cliente/candidato/verificar-entrevista/${candidato.id}/${interacao}`)
      .then((res) => {

        this.idCandidato = candidato.id

        if (interacao == 'consulta') this.candidato.entrevistas = res.data
        else if (interacao == 'avaliação') {

          if (res.data.possuiEntrevista == true) this.candidato.novaEntrevista = res.data.entrevistaSalva

        }
        
        this.candidato.nome = candidato.nome
        this.interacaoModalEntrevista = interacao
        this.$refs['modal-entrevista'].show()

      }).catch(err => this.msg("ATENÇÃO", err.response.data, "danger", 2000))

    },

    async saveEntrevista(acao) {

      this.interacaoModalEntrevista = 'carregando'

      const body = {

        idSolicitacao: this.idCandidato,
        descricao: this.candidato.novaEntrevista,
        acao: acao

      }

      await api.post('web-cliente/candidato/entrevista', body)
      .then(() => {

        if (acao == 'salvar') this.msg("ENTREVISTA", "SALVA COM SUCESSO.", "success", 2000);
        else this.msg("ENTREVISTA", "AVALIADA COM SUCESSO.", "success", 2000);
        this.$refs['modal-entrevista'].hide()
        this.candidato.novaEntrevista = ""
        this.refresh()

      }).catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    async getPermissoesUsuario(idUsuario, idEmpresa) {

      await api.get(`web-cliente/candidato/usuario-permissoes/${idUsuario}/${idEmpresa}`)
      .then((res) => {

        this.permissoesUsuario = res.data

      }).catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    async showModalAprovacaoContrato(candidato) {

      await api.get(`web-cliente/candidato/verificar-aprovacao-contrato/${candidato.id}`)
      .then((res) => {

        this.idCandidato = candidato.id
        this.candidato.dataEmissaoContrato = candidato.dataEmissaoContratoManual
        this.aprovacaoContrato = res.data
        this.candidato.dataAprovacaoContrato = ""
        this.$refs['modal-contrato-manual'].show()

      }).catch(err => this.msg("ATENÇÃO", err.response.data, "danger", 2000))

    }, 

    hideModalAprovacaoContrato() {

      this.idCandidato = ''
      this.aprovacaoContrato = ''
      this.dataAprovacaoContrato = ''
      this.candidato.dataAprovacaoContrato = ''
      this.$refs['modal-contrato-manual'].hide()

    },

    async aprovarContratoManual(idCandidato) {

      const body = {

        idSolicitacao: idCandidato,
        dataAprovacaoContrato: this.candidato.dataAprovacaoContrato

      }

      await api.put('web-cliente/candidato/aprovacao-contrato', body)
      .then(() => {

        this.hideModalAprovacaoContrato()
        this.msg('SUCESSO', 'CONTRATO MANUAL APROVADO', 'success', 2000)
        this.refresh()

      }).catch(err => this.msg("ATENÇÃO", err.response.data, "danger", 2000))

    },

    async desbloquearCandidato() {

      await api.put('web-cliente/candidato/desbloqueio', { idSolicitacao: this.idCandidato })
      .then((res) => {

        this.msg('SUCESSO', res.data, 'success', 2000)
        this.$refs['modal-confirm'].hide()
        this.refresh()

      }).catch(err => this.msg("ATENÇÃO", err.response.data, "danger", 2000))

    },

    direcionarAcaoCandidato(candidato, idPermissao) {

      if (idPermissao == 1) this.abrirAvaliar(candidato)
      else if (idPermissao == 2) this.abrirConsulta(candidato)
      else if (idPermissao == 3) this.modalHistorico(candidato)
      else if (idPermissao == 4) this.modalInatividade(candidato)
      else if (idPermissao == 5) this.modalRemoverCandidato(candidato)
      else if (idPermissao == 10) this.showModalConfirm(candidato, 'Confirma o reenvio da prova?')
      else if (idPermissao == 11) this.showModalConfirm(candidato, 'Confirma a liberação para captação de documentos?')
      else if (idPermissao == 12) this.showModalConfirm(candidato, 'Confirma o reenvio de nova documentação para assinatura?')
      else if (idPermissao == 20) this.enviarMensagem(candidato)
      else if (idPermissao == 30) this.abrirModalCopiarLink(candidato)
      else if (idPermissao == 101) this.showModalMeioAssinatura(candidato)
      else if (idPermissao == 102) this.modalHistoricoAssinaturas(candidato)
      else if (idPermissao == 103) this.showModalEntrevista(candidato, 'avaliação')
      else if (idPermissao == 104) this.showModalEntrevista(candidato, 'consulta')
      else if (idPermissao == 104) this.showModalEntrevista(candidato, 'consulta')
      else if (idPermissao == 105) this.showModalAprovacaoContrato(candidato)
      else if (idPermissao == 106) this.showModalConfirm(candidato, 'Confirma o desbloqueio do candidato?')
      else if (idPermissao == 107) this.showModalConfirm(candidato, 'Confirma a geração do contrato manual?')

    },

    async gerarContratoManual() {

      this.$refs['modal-confirm'].hide()

      await api.post(`web-cliente/candidato/contrato-manual/${this.idCandidato}`)
      .then(() => {

        this.msg("SUCESSO", 'CONTRATO MANUAL GERADO.', "success", 2000)
        this.refresh()

      })
      .catch(err => this.msg("ATENÇÃO", err.response.data, "danger", 2000))

    }
  
  },

  computed: {

    rows() {
      return this.items.length;
    },

    itemsFilter() {
      let valores = [];

      valores = this.items.filter((item) => {
        return (
          item.nome.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.email.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.celular.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.cpf.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.nomeFuncao.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.dataCreate.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
          item.progresso.toString().indexOf(this.search) > -1 ||
          item.status.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || 
          item.cidade.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").indexOf(this.search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")) > -1 
        );
      });
      return valores;
    },

    cssVars() {
      return {
        "--bg-color": this.bgColor,
        "--height": this.height + "px",
        "--font-color": this.fontColor,
      };
    },
    cssTitulo() {
      return {
        "--color": this.color,
        "--height": this.height + "px",
      };
    },

  }

};
</script>


<style lang="scss" scoped>

#gerenciarCandidatos {
  background-color: #EBEFF3;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgb(163 194 208 / 1%);
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: unset !important;
  border-top: rgb(163 194 208 / 1%);
}
#Candidatos {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 5rem;
  padding: 18px;
  margin-top: -65px;
  margin-left: 5%;
  width: 90%;
}

#Filtros {
  position: inherit;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: 5rem;
  padding: 18px;
  margin-top: 25px;
  margin-left: 5%;
  width: 90%;
}

.titulo {
  position: inherit;
  display: block;
  margin-top: 20px;
  margin-left: 5%;
}

.buscaMaisRefresh {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 480px) {
  #Candidatos {
    position: inherit;
    display: block;
    padding: 0;
    margin-bottom: -0;
    background-color: #f9f9f900;
    border: 1px solid rgba(0, 0, 0, 0);
    border: solid 1px rgba(204, 204, 204, 0);
  }

  #Filtros {
    position: inherit;
    display: block;
    padding: 0;
    margin-bottom: -0;
    background-color: #f9f9f900;
    border: 1px solid rgba(0, 0, 0, 0);
    border: solid 1px rgba(204, 204, 204, 0);
  }
}

@media screen and (max-width: 768px) {
  #Candidatos {
    width: 98%;
    position: inherit;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    padding: 18px;
    margin-top: 10px;
    margin-left: 7px;
  }

  #Filtros {
    width: 98%;
    position: inherit;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    padding: 18px;
    margin-top: 25px;
    margin-left: 7px;
  }
}

.contorno {
  padding: 10px;
  margin-top: 45px;
  margin-right: 10px;
  margin-left: 10px;
  border: solid 1px #cccc;
  border-radius: 5px;
  background-color: #fff;
}

@media (max-width: 480px) {
  .contorno {
    border: solid rgba(204, 204, 204, 0);
    padding: 0;
    margin-top: 25px;
    margin-right: 0;
    margin-left: 0;
  }
}

.display-4 {
  float: left;
  padding: 10px;
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.titulo {
  font-size: 2rem !important;
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  margin-top: 5px;
}

@media (min-width: 576px) {
}
.jumbotron {
  padding: 2rem 2rem;
}

@media (min-width: 480px) {
}
.jumbotron {
  padding: 1rem 1rem;
}

.pagination {
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
  justify-items: center;
  justify-content: center;
  margin-top: 2rem;
}

p {
  padding-left: 1rem;
}

.cor_danger {
  font-weight: 400;
  text-align: center;
  background: #8e1c42 !important;
  font-size: 1rem;
  line-height: 1.5;
}

.active {
  font-weight: 600;
  color: #ff0202 !important;
  background: none;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom-width: medium;
  border-bottom: 3px solid #ff0202;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
  background-color: #8e1c42 !important;
}

.box1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.card {
  margin-top: -25px;
}

.card {
  display: flex;
  border-radius: 6px;
  padding: 1%;
  margin-bottom: 1rem;
  background-color: var(--bg-color);
}

@media (max-width: 480px) {
  .card {
    padding: 2%;
  }
}

.card .card-title {
  margin-top: 0;
  margin-bottom: 3px;
  color: var(--color);
  // color: #fff;
}
.lero-btn {
  margin-left: 89%;
  justify-content: center;
  margin-top: -29px;
}

@media (max-width: 480px) {
  .lero-btn {
    margin-left: 62%;
  }
}

.box {
  width: 800px;
  padding: 40px;
  margin: 50px auto;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.1);
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 500%;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  font-size: 12px;
}

.nav-item > .nav-link {
  color: #fff;
}

.a {
  color: #000000 !important;
  text-decoration: none;
  background-color: transparent;
}


.bar[data-v-1057c27b] {
   width: 120px;
   padding: 0px 13px 15px 0px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-top: 1px;
    position: relative;
    height: 24px;
    border-radius: 3px;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 1px #fff, 0 1px 0 #fff;
}

// .bar[data-v-1057c27b] {
//     width: 120px;
//     margin-top: -15px;
//     position: absolute;
//     height: 22px;
//     border-radius: 3px;
//     overflow: hidden;
//     border-bottom: 1px solid #ddd;
//     box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.2), 0 -1px 1px #fff, 0 1px 0 #fff;
// }

.porcento {
  // float: right;
  font-weight: 600;
  line-height: 22px;
}

.progress {
  display: flex;
  // height: 30px;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #0da164;
  border-radius: 0.25rem;

  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba($color: #fff, $alpha: 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba($color: #fff, $alpha: 0.15) 50%,
    rgba($color: #fff, $alpha: 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress {
  float: left;
  padding: 15px;
}

.progress2 {
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #f50808;
  border-radius: 0.25rem;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba($color: #fff, $alpha: 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba($color: #fff, $alpha: 0.15) 50%,
    rgba($color: #fff, $alpha: 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress2 {
  float: left;
  padding: 15px;
}

.progress3 {
  display: flex;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #ffc107;
  border-radius: 0.25rem;
  background-size: 30px 30px;
  background-image: linear-gradient(
    135deg,
    rgba($color: #fff, $alpha: 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba($color: #fff, $alpha: 0.15) 50%,
    rgba($color: #fff, $alpha: 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.progress3 {
  float: left;
  padding: 15px;
}

.color1 {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #31d0ec;
  border-radius: 0.25rem;
}

.disabilitar {
  display: block;
}

@media (max-width: 480px) {
  .card .card-title[data-v-52e8430a] {
    margin-top: 0;
    margin-bottom: 3px;
    color: #fff;
    justify-content: center;
    justify-items: center;
    align-items: center;
    display: flex;
  }
}

.altura {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  height: min-content;
  min-height: 448px !important;
}

li {
  list-style: none;
}

.btn {
  padding: 0;
  font-size: 1rem;
  line-height: 2.0;
}

.btn-copiar-link {
  padding: 6px; 
}

.card[data-v-1057c27b] {
  display: flex;
  border-radius: 6px;
  padding: 1%;
  margin-bottom: 1rem;
  background-color: var(--bg-color);
  margin-top: 5px;
  border: 0px solid rgba(0, 0, 0, 0.125);
}

p[data-v-1057c27b] {
  padding-left: 0;
}
.modal-title {
  font-size: 28px !important;
}

.tituloHistorico h5 {
  font-size: 1.5rem;
  font-weight: 600;
  padding-left: 13px;
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa00;
  border-color: #f8f9fa00;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea00;
  border-color: #dae0e500;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff0;
  border: 1px solid #dee2e600;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.badge{
    padding: 5px 8px 7px;
}

.col-lg-12 {
  padding-left: 0;
  padding-right: 0;
}

.box-btn {
    width: 100%;
    display: flex;
    justify-content: center;
}

.btn-fechar {
    padding: 5px;
    width: 30%;
}

.icone {
    font-size: 25px;
}

.dropdown-acoes {
    border-radius: 70px;
    background-color: var(--bg-color);
}

.button-atualizar {
    background-color: var(--bg-color);
    border-color: var(--bg-color);
    color: var(--font-color)
}

.button-atualizar:hover {
    color: var(--font-color)
}

.button-atualizar:focus {
    background-color: var(--bg-color);
    border-color: var(--bg-color);
    color: var(--font-color);
}

.avatar-foto-perfil {
  margin-top: 15px;
}

// .info-celular {
//   font-size: 15px;
// }

</style>


