import api from "../../../../services/api";
import Header from '../../../../components/Header.vue'

export default {

    components: {
        Header,
    },

    data() {
        return {
            dadosEmpresa: null,
            bgColor: "#a1a1a1",

            documentacao: [],
            logo: "",
            listaImgRg: [], // monta html
            listaCarteiraConselho: [], // monta html
            listaNegativaConselho: [], // monta html
            listaDiploma: [], // monta html
            listaVacinacao: [], // monta html
            listaCurriculo: [], // monta html
            listaCriminais: [], // monta html
            listaAssinaturaCarimboCoren: [], // monta html
            listaBlocoVariavel_1500: [], // monta html
            listaBlocoVariavel_1510: [], // monta html
            listaBlocoVariavel_1520: [], // monta html
            listaBlocoVariavel_1530: [], // monta html
            listaBlocoVariavel_1540: [], // monta html


            nomeFoto: [],
            fotoPerfil: null,
            botaoVisivel: true,
            enderecoEdit: true,


            opcaoAvaliar: [
                { text: "Aprovar", value: "aprovar" },
                { text: "Reprovar", value: "reprovar" },

            ],

            motivosReprovacao: ["FOTO RASGADA", "FOTO ANTIGA"],



            estadoCivil: ["SOLTEIRO(A)", "CASADO(A)", "DIVORCIADO(A)", "SEPARADO(A)", "VIÚVO(A)"],

            racas: [
                "BRANCA",
                "PRETA",
                "PARDA",
                "AMARELA",
                "INDÍGENA",
                "NÃO INFORMADO",
            ],

            sexo: ["MASCULINO", "FEMININO"],
            fumante: ["SIM", "NÃO"],

            labelDadosAdicionais: '',

            escolaridades: [],
            paises: [],
            bancos: [],

            listaConhecimentosTecnicos: [],

            tipoContas: ["CORRENTE", "POUPANÇA"],

            //   dispPlantoes: [],
            listaPlantoes: [
                { text: "Diurno Impar", value: "diaImpar" },
                { text: "Diurno Par", value: "diaPar" },
                { text: "Noturno Impar", value: "noiteImpar" },
                { text: "Noturno Par", value: "noitePar" },
            ],




            //   selProcedimentos: [],
            listaProcedimentos: [
                { text: "Manhã", value: "manha" },
                { text: "Tarde", value: "tarde" },
                { text: "Noite", value: "noite" },
            ],

            tiposDependentes: [],
            dependente: {
                nome: null,
                cpf: null,
                dataNasc: null,
                tipo: null,
            },
            editarDep: false,
            editarIndex: 0,

            fields: [
                { key: "nome", label: "Nome" },
                { key: "cpf", label: "CPF" },
                {
                    key: "dataNasc",
                    label: "Data Nascimento",
                    formatter: (value) => {
                        const dataView = value.split("-");
                        return `${dataView[2]}/${dataView[1]}/${dataView[0]}`;
                    },
                },
                { key: "parentesco", label: "Tipo dependente" }

            ],

            form: {
                nome: "",
                email: "",
                cpf: "",
                estadoCivil: "",
                nacionalidade: "",
                escolaridade: "",
                raca: "",
                dataNasc: "",
                sexo: "",
                restFisica: "",
                fumante: "",
                dadosAdic: "",
                rg: "",
                rgOrgaoEmissor: "",
                rgDataExp: "",
                nomeFuncao: "",
                regProf: "",
                regNum: "",
                regDataVencimento: "",
                pis: "",
                tituloEleitor: "",
                ccm: "",
                cep: "",
                cidade: "",
                uf: "",
                bairro: "",
                endereco: "",
                numEndereco: "",
                complemento: "",
                celular: "",
                celAdic: "",
                fixo: "",
                banco: "",
                agencia: "",
                conta: "",
                contaDig: "",
                tipoConta: "",
                chavePix: "",
                nomeBeneficiario: "",
                cpfBeneficiario: "",
                file1: "",
                dependentes: [],
                urlFotoPerfil: null,
                urlRg: [],
                urlCarteiraConselho: [],
                urlNegativaConselho: [],
                urlDiploma: [],
                urlVacinacao: [],
                urlCurriculo: [],
                urlAntCriminais: [],
                urlAssinaturaCarimboCoren: [],
                urlBlocoVariavel_1500: [],
                urlBlocoVariavel_1510: [],
                urlBlocoVariavel_1520: [],
                urlBlocoVariavel_1530: [],
                urlBlocoVariavel_1540: [],
                ufNascimento: "",
                cidadeNascimento: "",
                nomeMae: "",
                nomePai: "",
                nomeConjuge: ""
            },

            validacoes: [],
            motivoPerfil: null,
            motivoDadosPessoais: null,
            motivoDadosPessoaisComp: null,
            motivoDadosComp: null,
            motivoDadosAdic: null,
            motivoRG: null,
            motivoDocProf: null,
            motivoPis: null,
            motivoTitulo: null,
            motivoCcm: null,
            motivoEndereco: null,
            motivoDadosBanc: null,
            motivoContaTerc: null,
            motivoConheTecnico: null,
            motivoPrefHorario: null,
            motivoDependentes: null,
            motivoCartConselho: null,
            motivoNegatConselho: null,
            motivoDiploma: null,
            motivoVacinacao: null,
            motivoCurriculo: null,
            motivoAntCriminais: null,
            motivoAssinaturaCarimboCoren: null,
            motivoDadosBlocoVariavel_1000: null,
            motivoDadosBlocoVariavel_1010: null,
            motivoDadosBlocoVariavel_1020: null,
            motivoDadosBlocoVariavel_1030: null,
            motivoDadosBlocoVariavel_1040: null,
            motivoDadosBlocoVariavel_1500: null,
            motivoDadosBlocoVariavel_1510: null,
            motivoDadosBlocoVariavel_1520: null,
            motivoDadosBlocoVariavel_1530: null,
            motivoDadosBlocoVariavel_1540: null,


            show: true,
            img1: false,
            img2: false,

            ref: null

        };
    },

    async created() {
        // console.log('rrrrrrrrrrrrrrrrrrrrrrrr')
        const dadosEmpresa = this.$store.state.empresaSelecionada
        this.labelDadosAdicionais = dadosEmpresa.labelDadosAdicionais
        this.bgColor = dadosEmpresa.cor;
        this.logo = dadosEmpresa.logoPath;
        this.blocos = dadosEmpresa.blocos;

        await this.getBlocosVisiveisDaFuncao()
        await this.getBlocosVisiveisDaAvaliacao()
        await this.getSolicitacao()

        this.loading = false
        this.getMotivos();
    },


    methods: {

        msg(titulo, texto, cor, time = 10000) {
            this.toastCount++;
            this.$bvToast.toast(texto, {
                title: titulo,
                variant: cor,
                solid: true,
                autoHideDelay: time,
                appendToast: true,
            });
        },

        blocoVisivel(codBloco) {

            let visivel = false
            this.blocos.forEach(item => {
                if (item.cod == codBloco && item.visivel == 'sim')
                    visivel = true
            })

            // console.log('blocooo',codBloco, visivel)

            return visivel
        },

        blocoObrigatorio(codBloco) {

            let obrigatorio = false
            this.blocos.forEach(item => {
                if (item.cod == codBloco && item.obrigatorio == 'sim')
                    obrigatorio = true
            })

            return obrigatorio
        },

        textoTitulo(codBloco, contemImagem) {

            let texto = ''
            
            if (contemImagem == 'sim') {

                this.blocos.forEach(item => {

                    if (item.cod == codBloco && item.imagem == contemImagem) texto = item.titulo

                })

            } else if (contemImagem == 'não') {

                this.blocos.forEach(item => {

                    if (item.cod == codBloco && !item.imagem) texto = item.titulo

                })

            } else {

                this.blocos.forEach(item => {

                    if (item.cod == codBloco) texto = item.titulo

                })

            }

            return texto

        },

        textoBloco(codBloco) {
            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.instrucoes
            })

            return texto
        },

        voltar() {
            this.$router.push("/candidatos");
        },





        async getBlocosVisiveisDaFuncao() {

            const idSolicitacao = localStorage.getItem('idSolicitacao')

            try {
                
                const res = await api.get("web-cliente/candidato/blocos-visiveis-funcao/" + idSolicitacao)
                console.log('getBlocosVisiveisDaFuncao',res)

                const blocosVisiveis = res.data
                this.blocos = blocosVisiveis?.length > 0 
                ? this.blocos.filter(bloco => {
                    return blocosVisiveis.find(blocoVisivel => Number(blocoVisivel) == bloco.cod)
                })
                : this.blocos

                console.log('getBlocosVisiveisDaFuncao this.blocos',this.blocos)
               
            } catch (error) {
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro");  
            }

        },

        async getBlocosVisiveisDaAvaliacao() {

            const idSolicitacao = localStorage.getItem('idSolicitacao')

            try {
                
                const res = await api.get("web-cliente/candidato/blocos-visiveis-reavaliar/" + idSolicitacao)
                console.log('getBlocosVisiveisDaAvaliacao',res)

                const blocosVisiveis = res.data
                this.blocos = blocosVisiveis?.length > 0 
                ? this.blocos.filter(bloco => {
                    return blocosVisiveis.find(blocoVisivel => Number(blocoVisivel) == bloco.cod)
                })
                : this.blocos

                console.log('getBlocosVisiveisDaFuncao this.blocos',this.blocos)
               
            } catch (error) {
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro");  
            }

        },

        getSolicitacao() {

            const idSolicitacao = localStorage.getItem('idSolicitacao')
            const dadosEmpresa = this.$store.state.empresaSelecionada

            this.escolaridades = dadosEmpresa.escolaridades;
            this.paises = dadosEmpresa.paises;
            this.bancos = dadosEmpresa.bancos;

            api.get("web-cliente/candidato/documentacao/" + idSolicitacao)
            .then((res) => {
                // console.log('res.data', res.data);

                this.form = res.data
                this.listaConhecimentosTecnicos = res.data.listaConhecimentosTecnicos
                this.form.validacoes = []

                this.imgRg = this.form.urlRg
                this.listaImgRg = this.form.urlRg.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCarteiraConselho = this.form.urlCarteiraConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaNegativaConselho = this.form.urlNegativaConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaDiploma = this.form.urlDiploma.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaVacinacao = this.form.urlVacinacao.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCurriculo = this.form.urlCurriculo.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCriminais = this.form.urlAntCriminais.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaAssinaturaCarimboCoren = this.form.urlAssinaturaCarimboCoren.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1500 = this.form.urlBlocoVariavel_1500.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1510 = this.form.urlBlocoVariavel_1510.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1520 = this.form.urlBlocoVariavel_1520.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1530 = this.form.urlBlocoVariavel_1530.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1540 = this.form.urlBlocoVariavel_1540.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

            })
            .catch(() => {
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro");
            });

        },

        getMotivos() {

            const dadosEmpresa = this.$store.state.empresaSelecionada

            api.get('web-cliente/candidato/motivos/'+dadosEmpresa.id)
            .then(res => {

                this.motivosReprovacao = res.data
            })
            .catch(err => this.msg("ATENÇÃO", err.response.data, "danger"))

        },

        addValidacao(el, bloco) {
            // console.log(el, bloco)

            let motivo = null
            // Del
            //--------------------------------------
            var index = null
            for (let i = 0; i < this.validacoes.length; i++) {
                if (this.validacoes[i].idBloco == bloco) {
                    // console.log(this.validacoes[i])
                    index = i
                    motivo = this.validacoes[i].idMotivoReprovacao
                }
            }
            if (index != null) 
            this.validacoes.splice(index, 1);
            //--------------------------------------

            if (el == 'aprovar') {
                motivo = null
                this.setMotivoVModel(bloco, null)
            }

            // Add
            //-------------------------------
            const ap = {
                idBloco: bloco,
                avaliacao: el,
                idMotivoReprovacao: motivo             
            }
            this.validacoes.push(ap)
            //-------------------------------

        },

        addMotivo(motivo, bloco) {
        
            this.setMotivoVModel(bloco, null)
        
            this.validacoes.forEach(item => {

                if (item.idBloco == bloco) {
                    if (item.avaliacao == 'reprovar') {
                        item.idMotivoReprovacao = motivo 
                        this.setMotivoVModel(bloco, motivo)  
                    } 
                }
            })
        },

        setMotivoVModel(bloco, motivo) {

            if (bloco == 150) this.motivoPerfil = motivo
            if (bloco == 10) this.motivoDadosPessoais = motivo 
            if (bloco == 11) this.motivoDadosPessoaisComp = motivo 
            if (bloco == 20) this.motivoDadosComp = motivo 
            if (bloco == 30) this.motivoDadosAdic = motivo 
            if (bloco == 40) this.motivoRG = motivo 
            if (bloco == 50) this.motivoDocProf = motivo 
            if (bloco == 60) this.motivoPis = motivo 
            if (bloco == 70) this.motivoTitulo = motivo 
            if (bloco == 80) this.motivoCcm = motivo 
            if (bloco == 90) this.motivoEndereco = motivo 
            if (bloco == 100) this.motivoDadosBanc = motivo 
            if (bloco == 110) this.motivoContaTerc = motivo 
            if (bloco == 120) this.motivoConheTecnico = motivo 
            if (bloco == 130) this.motivoPrefHorario = motivo 
            if (bloco == 140) this.motivoDependentes = motivo 
            if (bloco == 170) this.motivoCartConselho = motivo 
            if (bloco == 190) this.motivoNegatConselho = motivo 
            if (bloco == 180) this.motivoDiploma = motivo 
            if (bloco == 200) this.motivoVacinacao = motivo 
            if (bloco == 210) this.motivoCurriculo = motivo 
            if (bloco == 220) this.motivoAntCriminais = motivo 
            if (bloco == 51) this.motivoAssinaturaCarimboCoren = motivo 
            if (bloco == 1000) this.motivoDadosBlocoVariavel_1000 = motivo 
            if (bloco == 1010) this.motivoDadosBlocoVariavel_1010 = motivo 
            if (bloco == 1020) this.motivoDadosBlocoVariavel_1020 = motivo 
            if (bloco == 1030) this.motivoDadosBlocoVariavel_1030 = motivo 
            if (bloco == 1040) this.motivoDadosBlocoVariavel_1040 = motivo 
            if (bloco == 1500) this.motivoDadosBlocoVariavel_1500 = motivo 
            if (bloco == 1510) this.motivoDadosBlocoVariavel_1510 = motivo 
            if (bloco == 1520) this.motivoDadosBlocoVariavel_1520 = motivo 
            if (bloco == 1530) this.motivoDadosBlocoVariavel_1530 = motivo 
            if (bloco == 1540) this.motivoDadosBlocoVariavel_1540 = motivo 

        },

        saveAvaliacao(acaoAvaliar) {

            this.botaoVisivel = false

            const dadosEmpresa = this.$store.state.empresaSelecionada
            // console.log(dadosEmpresa)

            const dados = {
                idEmpresa: dadosEmpresa.id,
                nomeEmpresa: dadosEmpresa.nomeEmpresa,
                nome: this.form.nome,
                email: this.form.email,
                idSolicitacao: this.form.id,
                acao: acaoAvaliar,
                avaliacoes: this.validacoes
            }

            // Remove a cor vermelha do bloco que foi "pintado" de vermelho na tentativa anterior
            if (this.ref) document.getElementById(this.ref).style.backgroundColor = '#FFFFFF';
            
            api.post('web-cliente/candidato/avaliar', dados)
            .then(() => {

                this.msg("AVALIADO", 'com sucesso.', "success");

                const timer = setInterval(() => {

                    this.voltar()
                    clearInterval(timer)
    
                }, 3000)
                
            })
            .catch(err => {
                this.botaoVisivel = true

                if (typeof(err.response.data) != 'string') {

                    this.msg("ATENÇÃO", err.response.data.msg, "danger")

                    this.ref = err.response.data.codBloco.toString()

                    const el = this.$refs[this.ref]
                    el.scrollIntoView({ behavior: 'smooth'})

                    document.getElementById(this.ref).style.backgroundColor = '#F6DADD';

                } else {

                    this.msg("ATENÇÃO", err.response.data, "danger") 

                }

            })

        }



    },

    computed: {
        cssVars() {
            return {
                "--bg-color": this.bgColor,
                "--height": this.height + "px",
            };
        },
    },


};